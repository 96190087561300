<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <WelcomeMessage />
    
    <form class="intro-form" @submit.prevent="signUp">
      <h1>Registrer bruker</h1>
      <div class="form-row">
        <label class="label" for="email">E-post</label>
        <input
          class="input"
          id="email"
          type="email"
          v-model="email"
          autocomplete="email"
          required
        />
      </div>
      <div class="form-row">
        <label class="label" for="password">Passord</label>
        <input
          class="input"
          id="password"
          type="password"
          v-model="password"
          autocomplete="new-password"
          required
        />
      </div>
      <div class="form-row form-flex">
        <input
          id="terms"
          type="checkbox"
          v-model="slaAgreement"
          required
        >
        <label class="label label-white label-relative" for="terms">Jeg bekrefter at jeg er over 18 år, og godtar <a href="#" role="button" @click="showTermsModal = true">brukervilkårene</a></label>
      </div>
      <p v-if="errorMessage" class="alert">{{ errorMessage }}</p>
      <div class="btn-wrapper">
        <button :disabled="isLoading" type="submit" class="btn">Registrer</button>
      </div>
      <div v-if="validationEmailSent">
        <p>Det har nå blitt sendt en e-post til {{ email }}. Klikk på lenken i e-posten for å bekrefte opprettelsen av denne kontoen.</p>
      </div>
    </form>
    <p class="u-text-center">
      eller gå tilbake til
      <router-link
        :to="
          $route.query.returnUrl
            ? `/login?returnUrl=${$route.query.returnUrl}`
            : '/login'
        "
      >innlogging</router-link>.
    </p>

    <Modal :showModal="showTermsModal" @close="showTermsModal = false" class="wide-modal-content">
      <div class="modal-scrollable-content">
        <ServiceLevelAgreement />
      </div>
      
      <div class="modal-btn-wrapper">
        <button class="btn btn--secondary" type="button" @click="showTermsModal = false">Lukk</button>
      </div>
    </Modal>

    <Spinner v-if="isLoading" />
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { User } from "../../../shared/models/User";
import { fAuth, fAnalytics } from "../registerFirebase";
import { getFirebaseErrorMessage } from "../i18n/firebaseErrorMessages";
import router from "../router";
import userStore from "../store/userStore";
import ServiceLevelAgreement from "./SLA.vue";
import WelcomeMessage from "./WelcomeMsg.vue";

export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const errorMessage = ref("");
    const email = ref("");
    const password = ref("");
    const slaAgreement = ref("");
    const recaptchaResolved = ref(false);
    const showRecaptcha = ref(false);
    const validationEmailSent = ref(false);
    const { saveUser, createRandomUser } = userStore();
    const showTermsModal = ref(false);

    const isLoginValid = computed(() => !!email.value && !!password.value && !!slaAgreement.value);

    const signUp = () => {
      if (!isLoginValid.value) {
        errorMessage.value = getFirebaseErrorMessage(null);
        return;
      }
      isLoading.value = true;

      const userCredential = fAuth
        .createUserWithEmailAndPassword(email.value, password.value)
        .then(async (x) => {
          if (!x.user || !x.user.email) return;
          console.debug("fAuth user created", x.user);
          const user = createRandomUser(x.user.uid, x.user.email);
          await saveUser(user);
          fAnalytics.logEvent("user_created", { userId: x.user.uid });
          x.user.sendEmailVerification()
            .then(() => {
              validationEmailSent.value = true;
              window.location.replace("/profile");
            });
        })
        .catch((firebaseError) => {
          errorMessage.value = getFirebaseErrorMessage(firebaseError);
          console.debug("signup error", firebaseError);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      isLoading,
      errorMessage,
      email,
      password,
      slaAgreement,
      recaptchaResolved,
      showRecaptcha,
      showTermsModal,
      signUp,
    };
  },
  components: {
    ServiceLevelAgreement,
    WelcomeMessage
  }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>

<!-- 

// TODO: Reimplement this when phone login is go

//<button id="sign-in-with-phone-button" class="btn">Registrer med telefon</button>
// mounted() {
//   const vm = this as any;
//   const globalWindow = window as any;

//   // Start Firebase invisible reCAPTCHA verifier
//   globalWindow.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//     "sign-in-with-phone-button",
//     {
//       size: "invisible",
//       callback: () => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         // vm.sendSMS();
//         console.log("du er logget inn");
//       }
//     }
//   );

//   globalWindow.recaptchaVerifier.render().then((widgetId: any) => {
//     globalWindow.recaptchaWidgetId = widgetId;
//   });
// },

-->
